.reference-color-1 {
  background-color: #f9da78;
}
.reference-color-2 {
  background-color: #f6ab76;
}
.reference-color-3 {
  background-color: #bb271a;
}
.reference-color-4 {
  background-color: #bdd6ac;
}
.reference-color-5 {
  background-color: #a7c4e5;
}
.reference-color-6 {
  background-color: #b7b7b7;
  font-style: italic;
  text-decoration: line-through;
}
.reference-color-7 {
  background-color: #b7b7b7;
  font-style: italic;
  text-decoration: line-through;
}
.reference-color-8 {
  background-color: #b7b7b7;
  font-style: italic;
  text-decoration: line-through;
}
/* Loading */
.reference-color-9 {
  background-color: #b7b7b7;
  font-style: italic;
  text-decoration: line-through;
}

.reference-color-1:hover {
  background-color: #fbd762 !important;
  cursor: pointer;
}
.reference-color-2:hover {
  background-color: #f89c5a !important;
  cursor: pointer;
}
.reference-color-3:hover {
  background-color: #bb1607 !important;
  cursor: pointer;
}
.reference-color-4:hover {
  background-color: #aed992 !important;
  cursor: pointer;
}
.reference-color-5:hover {
  background-color: #8ab4e5 !important;
  cursor: pointer;
}
.reference-color-6:hover {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-7:hover {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-8:hover {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-9:hover {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}

.reference-color-1.Mui-selected {
  background-color: #fbd762 !important;
  cursor: pointer;
}
.reference-color-2.Mui-selected {
  background-color: #f89c5a !important;
  cursor: pointer;
}
.reference-color-3.Mui-selected {
  background-color: #bb1607 !important;
  cursor: pointer;
}
.reference-color-4.Mui-selected {
  background-color: #aed992 !important;
  cursor: pointer;
}
.reference-color-5.Mui-selected {
  background-color: #8ab4e5 !important;
  cursor: pointer;
}
.reference-color-6.Mui-selected {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-7.Mui-selected {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-8.Mui-selected {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
.reference-color-9.Mui-selected {
  background-color: #a1a1a1 !important;
  cursor: pointer;
}
